<template>
	<a-layout id="sys" style="min-height: 100vh">
		<a-drawer placement="right" width="300" :closable="false" @close="onClose" :visible="visible">
			<a-affix slot="handle" :style="{ position: 'fixed', top: '10%', right: '300px' }">
				<div class="screen-setting able" @click="showDrawer">
					<a-icon :type="visible ? 'close' : 'setting'"></a-icon>
				</div>
			</a-affix>
			<!-- 语言 -->
			<a-divider orientation="left">{{ $t('Language') }}</a-divider>
			<a-dropdown>
				<a class="able">
					<Icon-svg :iconClass="setting.curLanguage.country"></Icon-svg>
					<span>{{ setting.curLanguage.text }}</span>
				</a>
				<a-menu slot="overlay">
					<a-menu-item v-for="(item, index) in setting.languages" :key="index">
						<a @click="setLanguage(index)">
							<Icon-svg :iconClass="item.country"></Icon-svg>
							<span>{{ item.text }}</span>
						</a>
					</a-menu-item>
				</a-menu>
			</a-dropdown>
			<!-- 个人基本信息(名称,分组,绩效) -->
			<a-divider orientation="left">{{ $t('page.userInfo') }}</a-divider>
			<p class="drawer-content-line">
				<Icon-svg iconClass="headLady"></Icon-svg>
				<span>{{ user.userName }}</span>
			</p>
			<p class="drawer-content-line">
				<Icon-svg iconClass="baseInfo"></Icon-svg>
				<span>{{ user.teamName }}</span>
			</p>
			<!-- 系统操作 -->
			<a-divider orientation="left">{{ $t('operate.operation') }}</a-divider>
			<section class="operate">
				<a-tooltip placement="bottomLeft" :title="$t('operate.reload')">
					<span class="operate-i-wrap circle">
						<Icon-svg iconClass="reload"></Icon-svg>
					</span>
				</a-tooltip>
				<a-tooltip
					placement="bottomLeft"
					:title="$t('operate.toggleFold')"
					@click.native="() => (collapsed = !collapsed)"
					:autoAdjustOverflow="false"
				>
					<span class="operate-i-wrap">
						<Icon-svg :iconClass="collapsed ? 'menu-unfold' : 'menu-fold'"></Icon-svg>
					</span>
				</a-tooltip>
				<a-tooltip placement="bottomLeft" :title="$t('operate.exit')">
					<span class="operate-i-wrap" @click="logout()">
						<Icon-svg iconClass="exit"></Icon-svg>
					</span>
				</a-tooltip>
			</section>
		</a-drawer>
		<a-layout-sider class="mib-sider" collapsible v-model="collapsed" width="270">
			<div class="sider-logo">
				<img src="../assets/mib-logo.png" class="logo" alt="logo" />
				<h1 v-if="!collapsed">Bees Credit</h1>
			</div>
			<!--  :open-keys="openKeys" @openChange="onOpenChange" -->
			<a-menu theme="dark" mode="inline">
				<template v-for="item in navPermissions">
					<a-sub-menu :key="item.path" v-if="item.sub">
						<span slot="title"
							><a-icon type="menu" /><span>{{ $t(item.name) }}</span></span
						>
						<a-menu-item :key="item.path + childItem.path" v-for="childItem in item.sub">
							<router-link :to="`/home/${item.path}/${childItem.path}`">{{ $t(childItem.name) }}</router-link>
						</a-menu-item>
					</a-sub-menu>
					<a-menu-item :key="item.path + '1'" v-else>
						<router-link :to="`/home/${item.path}`">
							<a-icon type="menu" /><span>{{ $t(item.name) }}</span>
						</router-link>
					</a-menu-item>
				</template>
			</a-menu>
		</a-layout-sider>
		<a-layout-content style="background: #fff">
			<Tabpane>
				<div slot="router-view" class="mainContent">
					<router-view />
				</div>
			</Tabpane>
		</a-layout-content>
	</a-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiAdminAuth } from '@/api/common'
export default {
	computed: {
		...mapGetters(['setting', 'navPermissions', 'historyTabs', 'activeTab', 'user'])
	},
	created() {
		let arr = this.navPermissions
		let tabs = this.historyTabs
		// 取活跃的标签
		if (tabs.length > 0) {
			this.$router.replace(this.activeTab.fullPath).catch((e) => e)
		} else {
			// 取菜单数组第一个
			if (arr.length > 0 && arr[0].sub.length > 0) {
				this.$router.push(`/home/${arr[0].path}/${arr[0].sub[0].path}`)
			}
		}
		this.openKeys = [this.navPermissions[0].path]
		this.getAdminAuth()
	},
	data() {
		return {
			collapsed: false,
			visible: false,
			openKeys: []
		}
	},
	methods: {
		logout() {
			window.sessionStorage.clear()
			this.$store.commit('user/setLoginUser', {
				userId: null,
				isLogined: false
			})
			this.$store.commit('tabs/clearTab')
			this.$router.replace('/login')
		},
		showDrawer() {
			this.visible = !this.visible
		},
		onClose() {
			this.visible = false
		},
		setLanguage(index) {
			this.$store.commit('setting/setLang', index)
		},
		getAdminAuth() {
			apiAdminAuth({}).then((res) => {
				this.$store.commit('pageState/setAuthObj', res)
				this.timer = setTimeout(() => {
					this.getAdminAuth()
				}, 15 * 60 * 1000)
			})
		},
		onOpenChange(openKeys) {
			const latestOpenKey = openKeys.find((key) => this.openKeys.indexOf(key) === -1)
			const rootSubmenuKeys = this.navPermissions.map((item) => item.path)
			if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
				this.openKeys = openKeys
			} else {
				this.openKeys = latestOpenKey ? [latestOpenKey] : []
			}
		}
	},
	watch: {
		$route(to, from) {
			this.$store.commit('tabs/addTab', to)
		}
	}
}
</script>
<style lang="less" scoped>
.screen-setting {
	z-index: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	font-size: 16px;
	text-align: center;
	background: #1890ff;
	border-radius: 4px 0 0 4px;
	color: #ffffff;
}
.drawer-content-line {
	padding-bottom: 10px;
}
.operate {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
}
.operate .operate-i-wrap {
	flex: 0 0 auto;
	line-height: 50px;
	height: 50px;
	width: 50px;
}
html,
body,
#sys {
	height: 100vh;
	width: 100vw;
	font-size: 12px;
	box-sizing: border-box;
	overflow: hidden;
}
#sys .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

#sys .trigger:hover {
	color: #1890ff;
}

// #sys .logo {
// 	height: 45px;
// 	width: 45px;
// 	margin: 0 auto;
// 	// background-image: url(../assets/mib-logo.png);
// 	background-size: 100% 100%;
// }
.sider-logo {
	position: relative;
	display: flex;
	align-items: center;
	padding: 16px 24px;
	line-height: 32px;
	cursor: pointer;
	h1,
	img {
		margin-left: 20px;
		display: inline-block;
		height: 32px;
		vertical-align: middle;
	}
	h1 {
		margin: 0 0 0 12px;
		overflow: hidden;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		-webkit-animation: fade-in;
		animation: fade-in;
		-webkit-animation-duration: 0.2s;
		animation-duration: 0.2s;
	}
}
.ant-layout-sider-collapsed {
	.sider-logo {
		padding: 16px 8px;
	}
}
// #sys .logo2 {
// 	height: 90px;
// 	width: 65%;
// 	margin: 0 auto;
// 	background-image: url(../assets/mib-logo-x2.png);
// 	background-size: 100% 100%;
// }
#sys .mainContent {
	padding: 16px;
	border-top: none;
}
.performanceItem {
	padding: 5px;
}
.performance-span {
	padding: 0 5px;
}
.performanceSection {
	font-size: 16px;
	padding: 5px;
	font-weight: bold;
}
.mib-sider {
	.ant-menu {
		height: ~'calc(100% - 48px)'; // fixed的按钮占位
		overflow: auto;
	}
}
</style>
