import { _axios } from '@/plugins/axios'

export const apiLoanInterstRate = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/common/loanInterstRate`,
    method: method,
    data: dataJson
  })
}

export const apiAdminAuth = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/obs/adminAuth`,
    method: method,
    data: dataJson
  })
}
